import View from './view.js';
import icons from 'url:../../img/icons.svg';

class PaginationView extends View {
  _parentElement = document.querySelector('.pagination');

  addHandlerClick(handlerForward, handlerBackward) {
    this._parentElement.addEventListener('click', function (e) {
      const btn = e.target.closest('.btn--inline');
      if (!btn) return;
      if (btn.classList.contains('pagination__btn--next')) {
        handlerForward();
      }
      if (btn.classList.contains('pagination__btn--prev')) {
        handlerBackward();
      }
    });
  }

  _generateMarkup() {
    // console.log(this._data.currentPage);

    // Page 1, and there are other pages
    if (this._data.currentPage === 1 && this._data.numPages > 1) {
      return /*html*/ `
        <button class="btn--inline pagination__btn--next">
          <span>Page 2</span>
          <svg class="search__icon">
            <use href="${icons}#icon-arrow-right"></use>
          </svg>
        </button>`;
    }

    // Page 1, and there are no other pages
    if (this._data.currentPage === 1 && this._data.numPages === 1) {
      return /*html*/ ``;
    }

    // Other page
    if (
      this._data.currentPage > 1 &&
      this._data.currentPage < this._data.numPages
    ) {
      return /*html*/ `
        <button class="btn--inline pagination__btn--prev">
          <svg class="search__icon">
            <use href="${icons}#icon-arrow-left"></use>
          </svg>
          <span>Page ${this._data.currentPage - 1}</span>
        </button>
        <button class="btn--inline pagination__btn--next">
          <span>Page ${this._data.currentPage + 1}</span>
          <svg class="search__icon">
            <use href="${icons}#icon-arrow-right"></use>
          </svg>
        </button>`;
    }

    // Last page
    if (this._data.currentPage === this._data.numPages) {
      return /*html*/ `
        <button class="btn--inline pagination__btn--prev">
          <svg class="search__icon">
            <use href="${icons}#icon-arrow-left"></use>
          </svg>
          <span>Page ${this._data.currentPage - 1}</span>
        </button>`;
    }
  }
}

export default new PaginationView();
