import { TIMEOUT_SEC } from './config.js';

export const timeout = function (s) {
  return new Promise(function (_, reject) {
    setTimeout(function () {
      // console.log('time');
      reject(new Error(`Request took too long! Timeout after ${s} second`));
    }, s * 1000);
  });
};

export const AJAX = async function (url, uploadData = undefined) {
  try {
    console.log(url);

    const fetchPro = uploadData
      ? fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(uploadData),
        })
      : fetch(url);

    const response = await Promise.race([fetchPro, timeout(TIMEOUT_SEC)]);
    const dataJSON = await response.json();

    if (!response.ok) {
      throw new Error(`${dataJSON.message} (${response.status})`);
    }
    return dataJSON;
  } catch (err) {
    throw err;
  }
};

// export const getJSON = async function (url) {
//   try {
//     const response = await Promise.race([fetch(url), timeout(TIMEOUT_SEC)]);
//     const dataJSON = await response.json();

//     if (!response.ok) {
//       throw new Error(`${dataJSON.message} (${response.status})`);
//     }
//     return dataJSON;
//   } catch (err) {
//     throw err;
//   }
// };

// export const sendJSON = async function (url, uploadData) {
//   try {
//     const fetchPro = fetch(url, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(uploadData),
//     });

//     const response = await Promise.race([fetchPro, timeout(TIMEOUT_SEC)]);
//     const dataJSON = await response.json();

//     if (!response.ok) {
//       throw new Error(`${dataJSON.message} (${response.status})`);
//     }
//     return dataJSON;
//   } catch (err) {
//     throw err;
//   }
// };
